var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Large file storage"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-alert", { attrs: { type: "info", text: "", dense: "" } }, [
                _c("strong", [
                  _vm._v(
                    "Changes will take effect after the application is restarted."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.fetchingMountedStorageList
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              })
            : [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _vm.canMountStorage
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "primary" },
                            on: {
                              click: () => {
                                if (this.storageOptions.length === 0)
                                  this.fetchStorageOptions()
                                _vm.mountDialog = true
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("add"),
                            ]),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("attach"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        label: "Search for an attached storage...",
                        autofocus: "",
                        clearable: "",
                        "hide-details": "",
                        "prepend-inner-icon": "mdi-filter",
                        solo: "",
                        flat: "",
                        "background-color": "grey lighten-4",
                        dense: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.fetchingMountedStorageList,
                          icon: "",
                        },
                        on: { click: _vm.fetchMountedStorageList },
                      },
                      [_c("v-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  attrs: {
                    items: _vm.mountedStorageList,
                    "item-key": "amid",
                    headers: _vm.headers,
                    search: _vm.search,
                    options: _vm.tableOptions,
                    "footer-props": {
                      "items-per-page-options": _vm.itemsPerPageOptions,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "top",
                        fn: function ({ pagination, options, updateOptions }) {
                          return [
                            _c("v-data-footer", {
                              staticClass: "no-border",
                              attrs: {
                                pagination: pagination,
                                options: options,
                                itemsPerPageOptions: _vm.itemsPerPageOptions,
                                "items-per-page-text":
                                  "$vuetify.dataTable.itemsPerPageText",
                              },
                              on: { "update:options": updateOptions },
                            }),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                "No additional storage has been attached yet. Click attach to attach a new storage."
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `item.long_id`,
                        fn: function ({ item }) {
                          return [
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(_vm._s(item.long_id)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.quota_gib`,
                        fn: function ({ item }) {
                          return [_vm._v(_vm._s(item.quota_gib) + " GiB")]
                        },
                      },
                      {
                        key: `item.read_only`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.read_only ? "Read only" : "Read/Write"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.mount_path`,
                        fn: function ({ item }) {
                          return [
                            _c("code", { staticClass: "text-body-2" }, [
                              _vm._v(_vm._s(item.mount_path)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row justify-end" },
                              [
                                _c("copy-to-clipboard", {
                                  attrs: {
                                    textToCopy: item.mount_path,
                                    buttonClass: "caption font-weight-bold",
                                    tooltip: "Copy path to clipboard",
                                    iconButton: true,
                                  },
                                }),
                                _vm.canMountStorage
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                            color: "secondary",
                                                            loading:
                                                              _vm.editingMount ===
                                                              item.amid,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openEditMountDialog(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Edit attached storage"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.canMountStorage
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                            color: "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openDeleteDialog(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("delete")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Remove attached storage"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.dialogMaxWidth,
                "lazy-validation": "",
                persistent: "",
              },
              model: {
                value: _vm.editMountDialog,
                callback: function ($$v) {
                  _vm.editMountDialog = $$v
                },
                expression: "editMountDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", [_vm._v("Edit attached storage")]),
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "mb-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "mountEditForm",
                          model: {
                            value: _vm.mountEditFormIsValid,
                            callback: function ($$v) {
                              _vm.mountEditFormIsValid = $$v
                            },
                            expression: "mountEditFormIsValid",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.validChars,
                                _vm.rules.unique,
                              ],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [_vm._v("Path")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.mountPoint,
                              callback: function ($$v) {
                                _vm.mountPoint = $$v
                              },
                              expression: "mountPoint",
                            },
                          }),
                          _c("div", { staticClass: "mb-1" }, [
                            _vm._v("Storage will be accessible under"),
                          ]),
                          _c("code", { staticClass: "d-flex" }, [
                            _vm._v(" " + _vm._s(_vm.mountBasePath) + "/ "),
                            _c("b", [_vm._v(_vm._s(_vm.mountPoint))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          disabled:
                            _vm.selectedStorageOptionObject?.force_read_only,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v("Read only mode")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.mountReadOnly,
                          callback: function ($$v) {
                            _vm.mountReadOnly = $$v
                          },
                          expression: "mountReadOnly",
                        },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.closeEditMountDialog },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: !_vm.mountEditFormIsValid,
                            loading:
                              _vm.editingMount === _vm.selectedMount?.amid,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendPatchRequest()
                            },
                          },
                        },
                        [_vm._v(" Edit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.dialogMaxWidth,
                "lazy-validation": "",
                persistent: "",
              },
              model: {
                value: _vm.mountDialog,
                callback: function ($$v) {
                  _vm.mountDialog = $$v
                },
                expression: "mountDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Attach storage")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "mountCreateForm",
                          staticClass: "pt-4",
                          model: {
                            value: _vm.mountFormIsValid,
                            callback: function ($$v) {
                              _vm.mountFormIsValid = $$v
                            },
                            expression: "mountFormIsValid",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.storageOptions,
                              label: "Select a storage option",
                              "item-text": "long_id",
                              "item-value": "afsid",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item, on, attrs }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { "two-line": "" } },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.long_id)),
                                            ]),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.quota_gib) +
                                                  " GiB "
                                              ),
                                              item.force_read_only
                                                ? _c("strong", [
                                                    _vm._v("read only"),
                                                  ])
                                                : _vm._e(),
                                              item.cancellation_timestamp
                                                ? _c("span", [
                                                    _c("br"),
                                                    _vm._v(
                                                      " Available until " +
                                                        _vm._s(
                                                          _vm._f("dateToHuman")(
                                                            item.last_availability_date
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedStorageOption,
                              callback: function ($$v) {
                                _vm.selectedStorageOption = $$v
                              },
                              expression: "selectedStorageOption",
                            },
                          }),
                          _vm.alreadyMountedUnder
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    type: "warning",
                                    text: "",
                                    dense: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " This storage is already attached under "
                                  ),
                                  _c("code", [
                                    _vm._v(_vm._s(_vm.alreadyMountedUnder)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              hint: "This field is required",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.validChars,
                                _vm.rules.unique,
                              ],
                              dense: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [_vm._v("Path")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.mountPoint,
                              callback: function ($$v) {
                                _vm.mountPoint = $$v
                              },
                              expression: "mountPoint",
                            },
                          }),
                          _c("div", { staticClass: "mb-1" }, [
                            _vm._v("Storage will be accessible under"),
                          ]),
                          _c("code", { staticClass: "d-flex" }, [
                            _vm._v(" " + _vm._s(_vm.mountBasePath) + "/ "),
                            _c("b", [_vm._v(_vm._s(_vm.mountPoint))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          disabled:
                            _vm.selectedStorageOptionObject?.force_read_only,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v("Read only mode")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.mountReadOnly,
                          callback: function ($$v) {
                            _vm.mountReadOnly = $$v
                          },
                          expression: "mountReadOnly",
                        },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.mountDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: !_vm.mountFormIsValid,
                            loading: _vm.addingMount,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendPostRequest()
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }